import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import '../App.css';

const stats = [
  { number: 12, label: 'sal konferecyjnych i warsztatowych' },
  { number: 5, label: 'sal bankietowych' },
  { number: 1000, label: 'miejsc noclegowych' },
  { number: 3, label: 'restauracje' },
  { number: 2, label: 'strefy basenowe i SPA' },
  { number: 60, label: 'ha terenów na integracje' },
];

function Section25Part1() {
  return (
    <section className="current-section section25-part1">
      <Container className="section25-container">
        <Grid container spacing={2} direction="column" alignItems="center" className="section25-grid-mobile">
          {stats.map((stat, index) => (
            <Grid item xs={12} key={index}>
              <Paper elevation={3} className="section25-tile-mobile">
                <Typography variant="h2" className="section25-number">
                  {stat.number}
                </Typography>
                <Typography variant="body1" className="section25-text">
                  {stat.label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

export default Section25Part1;

import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Box, Paper, FormControlLabel, Checkbox, Link } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import '../App.css';

function Section1Part1() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    agreement: false,
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Imię jest wymagane';
    if (!formData.email) errors.email = 'E-mail jest wymagany';
    if (!formData.agreement) errors.agreement = 'Zgoda jest wymagana';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      // Submit form logic
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <section className="current-section section1-part1" style={{ backgroundColor: '#1d3972', paddingTop: '10px', paddingBottom: '10px' }}>
      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Grid container spacing={4} alignItems="center" className="section-content">
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: '#fff', textAlign: 'justify', marginBottom: '20px' }}>
              Zorganizuj niezapomniane wydarzenie w Czarna Góra Resort. Podczas gdy Ty będziesz budować relacje z gośćmi i korzystać z uroków obiektu oraz okolicy, my zajmiemy się całą resztą. Zadbamy o zakwaterowanie uczestników i prelegentów, sale konferencyjne, catering oraz atrakcje dla gości. Opowiedz nam o swoim projekcie.
            </Typography>
            <Paper elevation={3} className="section-form">
              <Typography variant="h6" style={{ marginBottom: '10px', textAlign: 'center', fontWeight: '600' }}>Zapytaj o ofertę</Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Imię"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                />
                <TextField
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
                <TextField
                  label="Numer telefonu"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Treść"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                  size="small"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement"
                      checked={formData.agreement}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                      Wyrażam zgodę na otrzymywanie od Sun & Snow Sp. z o.o. z siedzibą w Warszawie (00-362), ul. Gałczyńskiego 4 treści marketingowych zawierających w szczególności informacje o produktach i usługach Grupy Sun & Snow na podany przeze mnie numer telefonu.
                      <br />
                      <Link href="https://www.sunandsnow.pl/Content/documents/klauzula_pl.pdf" target="_blank" rel="noopener noreferrer">
                        Więcej informacji tutaj
                      </Link>
                    </Typography>
                  }
                  style={{ marginTop: '10px' }}
                />
                {formErrors.agreement && (
                  <Typography variant="body2" style={{ color: 'red', fontSize: '0.6rem' }}>
                    {formErrors.agreement}
                  </Typography>
                )}
                <Box display="flex" justifyContent="center" marginTop="10px">
                  <Button variant="contained" color="primary" type="submit" style={{ fontSize: '0.8rem' }}>
                    Wyślij
                  </Button>
                </Box>
              </form>
            </Paper>
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Button
                variant="contained"
                color="primary"
                startIcon={<PictureAsPdf />}
                style={{ backgroundColor: '#faad14', color: '#1d3972' }}
                href="https://www.sunandsnow.pl/Content/documents/Katalog_biznes_CGR_SP_k.pdf"
                target="_blank"
              >
                Pobierz katalog
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Section1Part1;

import React from 'react';
import { Container, Grid, Typography, Paper } from '@mui/material';
import '../App.css';

const Section25 = () => {
  return (
    <section className="section25">
      <Container className="section25-container">
        <div className="section25-top" />
        <div className="section25-bottom">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">12</Typography>
                <Typography className="section25-text">sal konferencyjnych i warsztatowych</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">5</Typography>
                <Typography className="section25-text">sal bankietowych</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">1000</Typography>
                <Typography className="section25-text">miejsc noclegowych</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">3</Typography>
                <Typography className="section25-text">restauracje</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">2</Typography>
                <Typography className="section25-text">strefy basenowe i SPA</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} className="section25-tile">
                <Typography component="h1" className="section25-number">60</Typography>
                <Typography className="section25-text">ha terenów na integracje</Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
};

export default Section25;

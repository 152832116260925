import React from 'react';
import { Container, Grid, Paper, Typography, Box } from '@mui/material';
import { Business, Event, BeachAccess } from '@mui/icons-material';
import '../App.css';

function Section2Part1() {
  return (
    <section className="current-section section2-part1">
      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Grid container spacing={4} alignItems="center" className="section-content">
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" justifyContent="space-around" height="100%">
              <Paper elevation={3} className="section2-tile">
                <Business fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Konferencje i szkolenia</Typography>
              </Paper>
              <Paper elevation={3} className="section2-tile">
                <Event fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Imprezy okolicznościowe</Typography>
              </Paper>
              <Paper elevation={3} className="section2-tile">
                <BeachAccess fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Wyjazdy urlopowe i workation</Typography>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="background-container section2-part1-background"></div>
    </section>
  );
}

export default Section2Part1;

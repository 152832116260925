import React, { useState } from 'react';
import { Container, Grid, Typography, Paper, Box } from '@mui/material';
import { KeyboardArrowDown, Business, Event, BeachAccess } from '@mui/icons-material';
import Modal from 'react-modal';
import '../App.css';

Modal.setAppElement('#root');

const imageLinks = [
  "https://bialaperla.pl/wp-content/uploads/2024/06/Czarna-Gora-Resort-scaled.jpg",
  "https://bialaperla.pl/wp-content/uploads/2024/06/Czarna-Gora-Resort-3-scaled.jpg",
  "https://bialaperla.pl/wp-content/uploads/2024/06/Czarna-Gora-Resort-15-scaled.jpg"
];

function Section2() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage("");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="current-section section2" id="section2">
      <Container style={{ zIndex: 2, position: 'relative' }}>
        <Grid container spacing={4} alignItems="center" className="section-content" style={{ height: 'calc(100% - 50px)' }}>
          <Grid item xs={12} md={4} style={{ textAlign: 'justify' }}>
            <Box display="flex" flexDirection="column" justifyContent="space-around" height="100%">
              <Paper elevation={3} className="section2-tile" style={{ height: '200px', width: '250px' }}>
                <Business fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Konferencje i szkolenia</Typography>
              </Paper>
              <Paper elevation={3} className="section2-tile" style={{ height: '200px', width: '250px' }}>
                <Event fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Imprezy okolicznościowe</Typography>
              </Paper>
              <Paper elevation={3} className="section2-tile" style={{ height: '200px', width: '250px' }}>
                <BeachAccess fontSize="large" className="section2-icon" />
                <Typography className="section2-text">Wyjazdy urlopowe i workstation</Typography>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1" className="section2-description">
              Nie musisz zajmować się samodzielnie wyszukiwaniem miejsca na konferencję lub szkolenie, ani zlecać komuś w firmie organizacji zakwaterowania dla pracowników w delegacji. Po prostu zadzwoń do Sun & Snow.
              <br /><br />
              <strong>Obsługa od A do Z</strong><br />
              Powiedz nam, czego potrzebujesz, a pomożemy Ci dobrać lokalizację i zaplanować wszystkie szczegóły organizacji konferencji, szkolenia, delegacji lub workation. Dzięki naszemu doświadczeniu, nie przeoczysz żadnego szczegółu.
              <br /><br />
              <strong>Opiekun biznesowy i lokalny</strong><br />
              Przy organizacji wydarzeń w naszych resortach oraz obsłudze wyjazdów otrzymujesz podwójną pomoc. Wspiera Cię opiekun z centrali, a na miejscu czuwa przedstawiciel w biurze lokalnym. Zawsze trzymamy rękę na pulsie.
              <br /><br />
              <strong>Wiele możliwości w jednym miejscu</strong><br />
              We współpracy z jednym partnerem, możesz wysyłać pracowników w wiele miejsc i organizować wydarzenia w różnych regionach kraju. Dbamy o to, aby zawsze oferować Ci najatrakcyjniejsze lokalizacje.
            </Typography>
            <Box display="flex" justifyContent="space-between" marginTop="20px">
              {imageLinks.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Czarna Góra Resort ${index + 1}`}
                  className="section2-image"
                  onClick={() => openModal(image)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className="scroll-down-arrow section2-arrow" onClick={() => scrollToSection('section3')}>
        <KeyboardArrowDown fontSize="large" />
      </div>
      <div className="background-container section2-background">
        <div className="background-left">
          <video
            src="https://bialaperla.pl/wp-content/uploads/2024/06/sale-konferencje.mp4"
            autoPlay
            loop
            muted
            className="background-video"
          />
        </div>
        <div className="background-right"></div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <img src={currentImage} alt="Current" className="modal-image" />
        <button onClick={closeModal} className="modal-close-button">Close</button>
      </Modal>
    </section>
  );
}

export default Section2;

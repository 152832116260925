import React from 'react';
import Section1 from './components/Section1';
import Section1Part1 from './components/Section1Part1';
import Section2 from './components/Section2';
import Section2Part1 from './components/Section2Part1';
import Section2Part2 from './components/Section2Part2';
import Section25 from './components/Section25';
import Section25Part1 from './components/Section25Part1';
import Section3 from './components/Section3';
import Section3Part1 from './components/Section3Part1';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

const theme = createTheme();

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {isMobile ? (
          <>
            <Section1Part1 />
            <Section2Part1 />
            <Section2Part2 />
            <Section25Part1 />
            <Section3Part1 />
          </>
        ) : (
          <>
            <Section1 />
            <Section2 />
            <Section25 />
            <Section3 />
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
